<template>
  <div class="history">
    <!-- tab -->
    <van-sticky>
      <van-tabs
        v-model="active"
        sticky
        offset-top="0px"
        @click="tabClick"
        swipeable
        color="#25262b"
        background="#ffc200"
      >
        <van-tab title="全部" name="0"></van-tab>
        <van-tab title="助餐" name="1"></van-tab>
        <van-tab title="助洁" name="2"></van-tab>
        <van-tab title="助行" name="3"></van-tab>
        <van-tab title="助浴" name="4"></van-tab>
        <van-tab title="助医" name="5"></van-tab>
        <van-tab title="康复" name="6"></van-tab>
        <van-tab title="巡访关爱" name="7"></van-tab>
        <van-tab title="辅助租赁" name="8"></van-tab>
        <van-tab title="其他" name="99"></van-tab>
      </van-tabs>
    </van-sticky>
    <!-- 列表内容 -->
    <div>
      <div class="historyList" v-for="item in list" :key="item.index">
        <div class="listTitle">
          <span>{{ item.SerKindDesc }}</span>
          <span>{{ item.SPName }}</span>
          <!-- <span>{{ item.OrderCode }}</span> -->
        </div>
        <div class="listSteps">
          <van-steps
            direction="vertical"
            :active="3"
            inactive-icon="checked"
            inactive-color="#07c160"
          >
            <van-step>
              <div>下单人 :{{ item.OMName }}</div>
              <div>联系方式 :{{ item.OMMobile }}</div>
            </van-step>
            <!-- <van-step  v-if="item.Status =='4'">
              <div>{{item.StaName}}</div>
              <div>{{item.OrderTime}}</div>
              <div v-if="item.Status =='4'">{{item.OrderTime}}</div>
            </van-step> -->
            <van-step>
              <div>服务站 :{{ item.StaName }}</div>
              <div>服务时间 :{{ item.SerTime }}</div>
            </van-step>
            <van-step>
              <div>服务商 :{{ item.EntName }}</div>
              <div>服务人员 :{{ item.SerMans }}</div>
              <div>服务内容 :{{ item.SerContent }}</div>
            </van-step>
            <!-- <van-step v-if="item.Status == '5'">
             <div>评价时间 :{{ item.DelTime }}</div>
              <div>
                满意度 :<van-rate readonly v-model="item.Satisfaction" />
              </div>
            </van-step>  -->
            <!-- <van-step v-if="item.Status == '9'">
              <div>撤单时间 :{{ item.RevTime }}</div>
              <div>撤单原因 :{{ item.RevReason }}</div>
            </van-step> -->
          </van-steps>
          <div
            style="width:100%;border:1px solid #ffc200;height85px;border-radius: 8px;"
            v-if="item.SerImgs"
          >
            <img
              v-for="items in item.SerImgsUrl"
              :key="items"
              :src="items"
              alt=""
              style="width: 70px; height: 70px; margin: 7px"
            />
          </div>
        </div>
        <van-button
          type="danger"
          size="small"
          class="deleteClass"
          @click="DeleteSPServiceRec(item.SPSId)"
          >删除</van-button
        >
      </div>
    </div>
    <!-- 没有更多 -->
    <div class="hidden">没有更多订单了~</div>
    <!-- 底部 -->
    <div>
      <van-tabbar v-model="tabbar" active-color="#ffc200">
        <van-tabbar-item icon="column" to="/CalYl/">订单管理</van-tabbar-item>
        <!-- <van-tabbar-item icon="goods-collect" to='/CalYl/service'>服务管理</van-tabbar-item> -->
        <van-tabbar-item icon="bookmark" to="/CalYl/history"
          >套餐服务</van-tabbar-item
        >
        <van-tabbar-item icon="manager" to="/CalYl/prompt"
          >个人中心</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import config from "@/config";
import { getToken } from "@/utils/auth";
import { WeGetStaSPServiceRecPage, WeDeleteSPServiceRec } from "@/api/Lycal.js";
export default {
  data() {
    return {
      tabbar: 1,
      active: 1,
      filters: {
        page: 1,
        limit: 999,
        serKind: 0,
      },
      acctoken: "",
      list: {},
      staService: {},
    };
  },
  mounted() {
    this.acctoken = getToken();
    this.getlist();
  },
  methods: {
    tabClick(e) {
      this.filters.serKind = e;
      scrollTo(0, 0);
      this.getlist();
    },
    // 获取列表
    getlist() {
      this.filters.accToken = this.acctoken;
      WeGetStaSPServiceRecPage(this.filters).then((res) => {
        this.list = res.data.data;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].SerImgs) {
            this.list[i].SerImgsUrl = this.list[i].SerImgs.split(",");
            for (let j = 0; j < this.list[i].SerImgsUrl.length; j++) {
              this.list[i].SerImgsUrl[j] =
                config.apiHttpsUrl + this.list[i].SerImgsUrl[j];
            }
          }
        }
      });
    },
    // 删除
    DeleteSPServiceRec(val) {
      Dialog.confirm({
        title: "提示",
        message: "是否删除套餐服务记录!",
      }).then(() => {
        WeDeleteSPServiceRec({ accToken: this.acctoken, SPSId: val }).then(
          (res) => {
            if (res.data.code == 0) {
              Toast.success("删除成功");
              this.getlist();
            } else {
              Toast.fail(res.data.msg);
            }
          }
        );
      });
    },
  },
};
</script>
<style scoped>
.history {
  background: #f7f7f9;
  padding-bottom: 60px;
}
.historyList {
  position: relative;
  width: 90%;
  margin: 12px 5%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #ccc;
}
.listTitle {
  position: relative;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f1f3f4;
}
.listTitle span:nth-child(1) {
  margin-left: 15px;
  font-size: 10px;
  background: #fed001;
  padding: 4px 10px;
  border-radius: 50px;
  font-style: italic;
  letter-spacing: 2px;
  border-bottom-left-radius: 0px;
}
.listTitle span:nth-child(2) {
  padding-left: 5px;
  font-size: 11px;
}
.listTitle span:nth-child(3) {
  position: absolute;
  right: 15px;
  font-weight: 700;
  font-size: 14px;
}
.listSteps {
  width: 90%;
  margin: 10px 5%;
  padding-bottom: 20px;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
}
.deleteClass {
  position: absolute;
  top: 10px;
  right: 12px;
}
</style>